<template>
    <div class="dialog__wrapper">
        <div class="dialog">
            <h2>
                Awesome!
            </h2>

            <p>
                Score: {{ score }}/{{ total }} ({{ (score / total).toFixed(1) * 100 }}%)
            </p>

            <p>
                Did you liked the Quiz? You have a suggestion? Need some help? Contact me on Twitter:
                <a
                    class="decoration relative"
                    href="https://twitter.com/denicmarko"
                    target="_blank"
                    rel="noopener nofollow"
                >
                    @denicmarko
                </a>
            </p>
            
            <p>
                Huge thanks to <a href="https://twitter.com/oliverjumpertz" rel="noopener nofollow">Oliver</a>, who wrote most of the questions.
            </p>

            <sharer />

            <p>
                <button
                    class="button"
                    @click="playAgain"
                >
                    Play again
                </button>
            </p>
        </div>
    </div>
</template>

<script>
    import Sharer from "../components/Sharer";

    export default {
        name: 'ScoreDialog',
        components: {Sharer},
        props: {
            score: {
                type: Number,
                required: true,
            },
            total: {
                type: Number,
                required: true,
            },
        },

        methods: {
            playAgain () {
                window.location.reload()
            }
        }
    }
</script>