<template>
    <div class="dialog__wrapper">
        <div class="dialog">
            <h2>
                Explanation!
            </h2>

            <p v-html="description" />

            <button class="button" @click="$emit('hide')">
                Close
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DescriptionDialog',

        props: {
            description: {
                type: String,
                required: true,
            }
        },
    }
</script>
