export default {
    questions: [
        {
            id: 1,
            credit: 'Oliver',
            code: 'const numbers = [33, 2, 8]; \nnumbers.sort();\nconsole.log(numbers[1])\n',
            description: 'This returns <code class="code--inline">33</code>, but why is that?<br><br>JavaScript is a dynamically typed language, which means that all standard library functionality must, at some point, decide how to work for most, if not all, use-cases.<br><br>Always keep in mind that the following array is valid: <br><br><div class="code--block"><code>const array = ["1", true, 55, 1.421, "foo", {}];</code></div><br><code class="code--inline">Array.prototype.sort</code> now needs to make a decision on how to handle such scenarios, and the solution is pretty straight-forward: <br><br>Convert all values to their string representation (because every value in JavaScript can always be converted to a string!), and then sort them in lexicographic order.<br><br>Which basically makes sort see the array as this: <br><br><div class="code--block"><code>const intermediate = ["33", "2", "8"];</code></div><br>And in lexicographic order, no matter how many characters a string has, comparison starts at position 0, and <code class="code--inline">"3"</code> comes before <code class="code--inline">"8"</code>. <br><br>And in the end, the result is this: <br><br><div class="code--block"><code>["2", "33", "8"];</code></div>',
            answers: [
                {
                    id: 1,
                    text: '33',
                    correct: true,
                },
                {
                    id: 2,
                    text: '2',
                    correct: false,
                },
                {
                    id: 3,
                    text: '8',
                    correct: false,
                },
                {
                    id: 4,
                    text: '1',
                    correct: false,
                },
            ],
        },
        {
            id: 2,
            credit: 'Oliver',
            text: 'Can you guess the result?',
            code: 'console.log((\'b\' + \'a\' + + \'a\' + \'a\').toLowerCase());\n',
            description: 'The answer is <code class="code--inline">"banana"</code>.<br><br>What actually happens here is the following: <br><br>The plus operator is defined for numbers and strings and as soon as a string is present on either the left or right side, a string concatenation is perfomed. <br><br>If we follow the execution path, this is what happens: <br><br><div class="code--block"><code>// 1st step <br>\'b\' + \'a\' -> \'ba\' <br>// 2nd step <br>\'ba\' + + \'a\' // wait a second!</code></div><br>There are two plus operators in this expression. But one of those is actually a prefix-operator, and not a classical plus. <br>What it does is converting the right-side argument to a number, but converting <code class="code--inline">\'a\'</code> to a number will actually yield <code class="code--inline">NaN</code>!<br>Let\'s continue with following the execution path: <br><br><div class="code--block"><code>// 1st step <br>\'b\' + \'a\' -> \'ba\' <br>// 2nd step <br>\'ba\' + + \'a\' -> \'ba\' + NaN -> \'baNaN\'<br>// 3rd step <br>\'baNaN\' + \'a\' -> \'baNaNa\'<br>// 4th step <br>\'baNaNa\'.toLowerCase() -> \'banana\'</code></div>',
            answers: [
                {
                    id: 1,
                    text: 'bananaa',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'baaa',
                    correct: false,
                },
                {
                    id: 3,
                    text: 'banana',
                    correct: true,
                },
                {
                    id: 4,
                    text: 'ananas',
                    correct: false,
                },
            ],
        },
        {
            id: 3,
            credit: 'Oliver',
            text: 'Can you guess what will be the output of the code below?',
            code: 'console.log(typeof NaN);\n',
            description: '<code class="code--inline">typeof NaN</code> actually returns <code class="code--inline">"number"</code>, but why is that?<br><br> You can actually thank the IEEE Standard for Floating-Point Arithmetic (IEEE 754), which is the specification most programming languages base their implementation of floating point numbers on.<br><br>Imagine it this way:<br><br>Let\'s use TypeScript here as an example to bring statical typing to JavaScript and imagine the following scenario: <br><br><div class="code--block"><code>const result: number = parseFloat(inputArgument);</code></div><br>The code is pretty straight-forward. We want a number, and we have to parse an input argument for that.<br><br>But what if parseFloat actually cannot parse a float?<br><br>What if we forgot to validate the input and are now passing <code class="code--inline">\'Does this work?\'</code> to parseFloat?<br><br>And this is where NaN comes into the game.<br><br>NaN simply states: "This is not a number", but in a type-safe way, so you always get a result of type <code class="code--inline">number</code> .',
            answers: [
                {
                    id: 1,
                    text: 'NaN',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'number',
                    correct: true,
                },
                {
                    id: 3,
                    text: 'null',
                    correct: false,
                },
                {
                    id: 4,
                    text: 'undefined',
                    correct: false,
                },
            ],
        },
        {
            id: 4,
            credit: 'Oliver',
            code: 'console.log(018 - 015);\n',
            description: 'This will actually result in <code class="code--inline">5</code> but this is pretty strange, isn\'t it? <br><br>Please note, that this example will only work in lax mode, and not in strict mode, and we will come to the explanation soon enough.<br><br>In earlier versions of JavaScript, a leading 0 on a number marked an octal number.<br><br>Octal numbers are numbers with a base of 8 (instead 10 like with decimal numbers).<br><br>This means that numbers from 0 to 7 are valid digits.<br><br>In our case, <code class="code--inline">018</code> can\'t actually be a octal number, can it?<br><br>Well it can\'t and the runtime knows this, which is why it treats <code class="code--inline">018</code> as <code class="code--inline">18</code> although the right side contains a perfectly valid octal number <code class="code--inline">015</code> which is <code class="code--inline">13</code> in decimal.<br><br>And the runtime will perform the calculation exactly like this, which results in: <br><br><div class="code--block"><code>18 - 13 -> 5</code></div>',
            answers: [
                {
                    id: 1,
                    text: 'NaN',
                    correct: false,
                },
                {
                    id: 2,
                    text: '3',
                    correct: false,
                },
                {
                    id: 3,
                    text: '5',
                    correct: true,
                },
                {
                    id: 4,
                    text: '13',
                    correct: false,
                },
            ],
        },
        {
            id: 5,
            credit: 'Oliver',
            code: 'console.log("This is a string." instanceof String);\n',
            description: 'This actually returns false.<br><br>The reason for that circumstance is that JavaScript distinguishes between primitives and objects.<br><br>And "This is a string." is actually a primitive string and not an instance of the object String.<br><br>If the code was like this: <br><br><div class="code--block"><code>new String("This is a string.") instanceof String</code></div><br>you\'d actually get the result you would have expected at the beginning. <br><br>What <code class="code--inline">instanceof</code> actually does is checking if the String constructor is nested within the prototype chain of the value provided.<br><br>In this case, it isn\'t.',
            answers: [
                {
                    id: 1,
                    text: 'true',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'false',
                    correct: true,
                },
            ],
        },
        {
            id: 6,
            credit: 'Oliver',
            code: 'console.log(0.1 + 0.2 == 0.3);\n',
            description: 'The answer is <code class="code--inline">false</code>.<br><br>This is not a JavaScript quirk but actually based on floating point arithmetic.<br>Some decimal numbers simply can\'t be represented accurately by computers and some decimal numbers can\'t be added up accurately.<br>This is the case in this situation. <br><br><div class="code--block"><code>0.1 + 0.2 -> 0.30000000000000004</code></div><br>And <code class="code--inline">0.30000000000000004</code> is never equal to <code class="code--inline">0.3</code>.',
            answers: [
                {
                    id: 1,
                    text: 'true',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'false',
                    correct: true,
                },
            ],
        },
        {
            id: 7,
            credit: 'Oliver',
            code: 'console.log(1 +  "2" + "2");\n',
            description: 'The result is <code class="code--inline">"122"</code>, but why is that? <br><br>The plus operator is defined for numbers and for strings and the expression is evaluated from left to right.<br><br>The interesting thing about the algorithm behind it is, that it checks whether a string is present.<br><br>If we take a look at how the runtime handles the expression, it will start with the first part as follows: <br><br><div class="code--block"><code>1 + "2"</code></div><br>And as the right side contains a string, a concatenation will be made, which results in n intermediary result of <br><br><div class="code--block"><code>1 + "2" -> "12"</code></div><br> Only after that, the last part is evaluated as follows: <br><br><div class="code--block"><code>"12" + "2" -> "122"</code></div>',
            answers: [
                {
                    id: 1,
                    text: '122',
                    correct: true,
                },
                {
                    id: 2,
                    text: '32',
                    correct: false,
                },
                {
                    id: 3,
                    text: 'NaN2',
                    correct: false,
                },
                {
                    id: 4,
                    text: 'NaN',
                    correct: false,
                },
            ],
        },
        {
            id: 8,
            credit: 'Oliver',
            code: 'console.log(false == \'0\');\n',
            description: 'The answer is <code class="code--inline">true</code>.<br><br>In this situation, the abstract equality comparison algorithm is used, which is also called the type-coercing equality check.<br>What it does is converting the values as long as possible, until they match in type and can be compared strictly.<br><br>In this case, the following steps are performed:<br><br><div class="code--block"><code>// 1st step<br>false == \'0\'<br><br>// 2nd step<br>Number(false) == \'0\' -> 0 == \'0\'<br><br>// 3rd step\n0 == \'0\' -> 0 == Number(\'0\')<br><br>// 4th step<br>0 == 0 -> 0 === 0 -> true</code></div>',
            answers: [
                {
                    id: 1,
                    text: 'false',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'true',
                    correct: true,
                },
            ],
        },
        {
            id: 9,
            credit: 'Oliver',
            code: 'let array = [1, 2, 3];\narray[6] = 9;\nconsole.log(array[5]);\n',
            description: 'The answer is <code class="code--inline">undefined</code>.<br><br>This quiz starts with an array of length 3, containing 3 numbers.<br><br>By not using <code class="code--inline">Array.prototype.push</code> and then assigning a value to a position in the array, that is out of the bounds of the original array, the array is extended automatically by the runtime while also increasing the <code class="code--inline">length</code> property.<br><br>But this leads to the interesting behavior, that a lot of holes are created within the array.<br><br>After the expression <code class="code--inline">array[6] = 9;</code> the array looks as follows: <br><br><div class="code--block"><code>//  0  1  2     3           4          5      6     <- indices <br>   [1, 2, 3, undefined, undefined, undefined, 9]</code></div><br>And this is why, when accessing <code class="code--inline">array[5]</code> <code class="code--inline">undefined</code> is returned.',
            answers: [
                {
                    id: 1,
                    text: '1',
                    correct: false,
                },
                {
                    id: 2,
                    text: '2',
                    correct: false,
                },
                {
                    id: 3,
                    text: '9',
                    correct: false,
                },
                {
                    id: 4,
                    text: 'undefined',
                    correct: true,
                },
            ],
        },
        {
            id: 10,
            credit: 'Oliver',
            code: 'console.log(typeof typeof 1);\n',
            description: 'This actually returns <code class="code--inline">"string"</code>.<br><br>This expression is evaluated from right to left.<br><br>The first sub-expression evaluated actually is <code class="code--inline">typeof 1</code> which will return <code class="code--inline">"number"</code>.<br><br>Only after that the next sub-expression is evaluated which now is <code class="code--inline">typeof "number"</code> which returns <code class="code--inline">"string"</code>.',
            answers: [
                {
                    id: 1,
                    text: 'string',
                    correct: true,
                },
                {
                    id: 2,
                    text: 'number',
                    correct: false,
                },
                {
                    id: 3,
                    text: '1',
                    correct: false,
                },
                {
                    id: 4,
                    text: 'true',
                    correct: false,
                },
            ],
        },
        {
            id: 11,
            credit: 'Oliver',
            code: 'console.log(3 > 2 > 1);\n',
            description: 'While this is a perfectly valid mathematical comparison,<br>which would usually yield true, programming languages evaluate expressions<br>differently, and JavaScript itself is no different.<br><br>We have to split the expression into two parts:<br>First of all, 3 > 2 gets evaluated, which yields true.<br>3 > 2 === true<br><br>Now the rest has to be evaluated:<br>true > 1<br>This isn\'t directly possible, as the types are different so coercion happens under the hood.<br>In case of the greater-than (>) operator, a type hint of number is passed so true is converted to a number.<br>Number(true) > Number(1)<br>=> 1 > 1<br>=> false',
            answers: [
                {
                    id: 1,
                    text: 'true',
                    correct: false,
                },
                {
                    id: 2,
                    text: 'false',
                    correct: true,
                },
            ],
        },
        {
            id: 12,
            credit: 'Oliver',
            code: 'console.log(String.raw`HelloTwitter\\nworld`);\n',
            description: 'What String.raw actually does is taking a template literal, processing all substitutions (${variable}, e.g.), but ignoring well-known escape-sequences.<br><br>**The following will be printed above: HelloTwitter\\nworld** <br><br>\\n is a well-known escape-sequence, like \\t is, e.g.<br><br>Those will simply be ignored and put into the resulting string, as they are.<br><br>Here an example of how String.raw works when you also include substitutions: <br><br><div class="code--block"><code>const varOne = "Hey";<br>const varTwo = "there";<br>const str = String.raw`${varOne}\\t\\n${varTwo}` // => "Hey\\t\\nthere"</code></div>',
            answers: [
                {
                    id: 1,
                    text: '"HelloTwitter\\nworld"',
                    correct: true,
                },
                {
                    id: 2,
                    text: '"HelloTwitter<br>world"',
                    correct: false,
                },
                {
                    id: 3,
                    text: '"HelloTwitter world"',
                    correct: false,
                },
                {
                    id: 4,
                    text: '"Hello Twitter world"',
                    correct: false,
                },
            ],
        },
        {
            id: 13,
            credit: 'Oliver',
            code: 'const isTrue = true == [];\nconst isFalse = true == ![];\n\nconsole.log(isTrue + isFalse);\n',
            description: 'Let\'s break this up:<br>The comparison performed is the abstract comparison operation,<br>which uses type coercion under the hood, when necessary.<br><br>As the first parameter is a boolean, the following rule applies:<br>"If Type(x) is Boolean, return the result of the comparison ToNumber(x) == y."<br>And in the next round, the next rule applies,<br>"If Type(y) is Boolean, return the result of the comparison x == ToNumber(y)."<br><br>Which then boils down to:<br>Number(true) == Number([])<br>=> 1 == 0 <br><br><div class="code--block"><code>const isTrue = true == []; // => false</code></div><br>Here, two booleans are present. <br>The right side gets converted through the unary negation, and then negated. <br>An empty array is truthy, converting and negating it yields false. <br>true == false <br><br><div class="code--block"><code>const isFalse = true == ![]; // => false</code></div><br>This now evaluates to: false + false <br>which is coered to: <br>Number(false) + Number(false) <br>=> 0 + 0 <br><br> <div class="code--block"><code>console.log(isTrue + isFalse); // => prints 0</code></div>',
            answers: [
                {
                    id: 1,
                    text: '1',
                    correct: false,
                },
                {
                    id: 2,
                    text: '0',
                    correct: true,
                },
                {
                    id: 3,
                    text: '"true"',
                    correct: false,
                },
                {
                    id: 4,
                    text: 'true',
                    correct: false,
                },
            ],
        },
    ]
}
