<template>
    <div class="dark-mode-switcher">
        <svg
            v-if="!darkMode"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="toggle"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 3c0.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
        </svg>

        <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#fff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="toggle"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="4" />
            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" />
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'DarkModeSwitcher',

        data () {
            return {
                darkMode: false,
            }
        },

        mounted () {
            let darkMode = localStorage.getItem('darkMode');

            if (darkMode === 'true') {
                this.darkMode = true;
                document.body.classList.add('dark-mode');
            }
        },

        methods: {
            toggle () {
                if (!this.darkMode) {
                    this.darkMode = true;
                    document.body.classList.add('dark-mode');
                    localStorage.setItem('darkMode', 'true');
                } else {
                    this.darkMode = false;
                    document.body.classList.remove('dark-mode');
                    localStorage.setItem('darkMode', 'false');
                }
            }
        }
    }
</script>

<style lang="less">
    .dark-mode-switcher {
        position: absolute;
        top: 1em;
        right: 1em;
        cursor: pointer;
    }
</style>
