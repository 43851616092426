<template>
    <div class="dialog__wrapper">
        <div class="dialog">
            <h2>
                Welcome to JavaScript Quiz!
            </h2>

            <p>
                After answering on a question, you can click on the
                <button
                    class="button button--icon dif default"
                >
                    <svg viewBox="0 0 20 20" fill="currentColor" class="icon icon--medium">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                    </svg>
                </button>
                icon to see the explanation.
            </p>

            <p>
                Are you ready to start?
            </p>

            <button class="button" @click="$emit('hide')">
                I'm ready!
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WelcomeDialog',
    }
</script>
