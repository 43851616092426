<template>
    <div>
        <div class="question">
            <div class="question__nav">
                <div
                    class="question__arrow question__arrow--left"
                    :class="order === 1 ? 'disabled' : ''"
                >
                    <svg @click="prev" viewBox="0 0 20 20" fill="currentColor" class="icon">
                        <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                    </svg>
                </div>

                <div class="question__order">
                    {{ order }}/{{ total }}
                </div>

                <div
                    class="question__arrow question__arrow--right"
                    :class="!localQuestion.answered ? 'disabled' : ''"
                >
                    <svg @click="next" viewBox="0 0 20 20" fill="currentColor" class="icon">
                        <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                </div>
            </div>

            <div class="question__text">
                Question: {{ localQuestion.text ? localQuestion.text : defaultText }}
            </div>

            <div class="question__code">
                <svg class="question__dots" xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                        <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" stroke-width=".5"></circle>
                        <circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" stroke-width=".5"></circle>
                        <circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" stroke-width=".5"></circle>
                    </g>
                </svg>

                <textarea :id="id" v-model="localQuestion.code"></textarea>

                <transition name="fade">
                    <div
                        class="question__next"
                    >
                        <button
                            class="button button--icon"
                            v-if="localQuestion.answered && localQuestion.description"
                            @click="showDescription"
                        >
                            <svg viewBox="0 0 20 20" fill="currentColor" class="icon icon--medium">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                            </svg>
                        </button>

                        <button
                            class="button button--small"
                            v-if="localQuestion.answered"
                            @click="next"
                        >
                            {{ order === total ? 'Score' : 'Next' }}
                        </button>
                    </div>
                </transition>
            </div>

            <div
                class="question__answers"
            >
                <button
                    class="button question__single-answer"
                    :class="{ 'disabled': localQuestion.answered, 'button--green': localQuestion.answered && answer.correct, 'button--red': localQuestion.answered === answer.id && !answer.correct }"
                    v-for="(answer, index) in localQuestion.answers"
                    :key="index"
                    @click="sendAnswer(answer)"
                    :disabled="localQuestion.answered"
                    v-html="answer.text"
                />
            </div>
        </div>

        <div class="tac mb-1">
            <small>
                Question by {{ question.credit }}
            </small>
        </div>
    </div>
</template>

<script>
    import CodeMirror from 'codemirror'

    export default {
        name: 'Question',
        props: {
            question: {
                type: Object,
                required: true,
            },
            order: {
                type: Number,
                required: true,
            },
            total: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                id: 'code-' + this._uid,
                defaultText: 'Do you know what will be the output of this code?',
                localQuestion: this.question,
            }
        },

        mounted() {
            this.init();
        },

        methods: {
            init () {
                CodeMirror.fromTextArea(document.getElementById(this.id), {
                    lineNumbers: true,
                    mode: 'javascript',
                    theme: 'monokai',
                    readOnly: true,
                    viewportMargin: Infinity
                });
            },
            sendAnswer (answer) {
                if (this.localQuestion.answered) return;
                // eslint-disable-next-line vue/no-mutating-props
                this.localQuestion.answered = answer.id;
            },
            prev () {
                if (this.order === 1) return;
                this.$emit('prev');
            },
            next () {
                if (!this.localQuestion.answered) return;
                this.$emit('next');
            },
            showDescription () {
                if (!this.localQuestion.answered) return;
                this.$emit('description', true, this.localQuestion.description);
            }
        }
    }
</script>

<style lang="less">
    .question {
        border: 1px solid var(--border-color);
        margin-bottom: 5px;
        &__nav {
            padding: 7px 10px;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: left;
            align-items: center;
        }
        &__arrow {
            display: flex;
            color: var(--primary-color);
            cursor: pointer;
            &--left {
                padding-right: 5px;
            }
            &--right {
                padding-left: 5px;
            }
        }
        &__order {
            padding: 0 5px;
            border-right: 1px solid var(--border-color);
            border-left: 1px solid var(--border-color);
            letter-spacing: 1px;
            font-size: 14px;
        }
        &__text {
            padding: 20px;
        }
        &__code {
            border: 20px solid #585858;
            background-color: #272822;
            position: relative;
        }
        &__dots {
            margin: 10px 18px;
        }
        &__next {
            display: flex;
            position: absolute;
            right: 5px;
            bottom: 5px;
            z-index: 99;
        }
        .question__single-answer {
            border-bottom: 1px solid var(--border-color);
            width: 100%;
            transition: opacity 0.4s;
            &:hover {
                opacity: 0.8;
            }
            &.answered {
                &--correct {
                    background-color: #67C23A;
                }
                &--incorrect {
                    background-color: #F56C6C;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .CodeMirror {
            position: relative;
            overflow: hidden;
            height: auto;
            padding-bottom: 10px;
            pre.CodeMirror-line {
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: normal;
            }
        }
    }
</style>
