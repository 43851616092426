<template>
    <div class="quiz">
        <template
            v-for="(question, index) in questions"
        >
            <question
                v-if="currentIndex === index"
                :key="question.id"
                :order="index + 1"
                :total="questions.length"
                :question="question"
                @prev="prev"
                @next="next"
                @description="toggleDescriptionDialog"
            />
        </template>

        <transition name="fade">
            <welcome-dialog
                v-if="showWelcomeDialog"
                @hide="hideWelcomeDialog"
            />
        </transition>

        <transition name="fade">
            <score-dialog
                v-if="showScoreDialog"
                :total="questions.length"
                :score="score"
            />
        </transition>

        <transition name="fade">
            <description-dialog
                v-if="showDescriptionDialog"
                :description="description"
                @hide="toggleDescriptionDialog"
            />
        </transition>
    </div>
</template>

<script>
    import Question from '../components/Question'
    import WelcomeDialog from '../dialogs/WelcomeDialog'
    import ScoreDialog from '../dialogs/ScoreDialog'
    import DescriptionDialog from '../dialogs/DescriptionDialog'
    import config from '../config'
    import shuffle from '../mixins/shuffle';

    export default {
        name: 'Quiz',

        components: {
            Question,
            WelcomeDialog,
            ScoreDialog,
            DescriptionDialog,
        },

        data () {
            return {
                currentIndex: 0,
                questions: [],
                showWelcomeDialog: true,
                showScoreDialog: false,
                description: '',
                showDescriptionDialog: false,
            }
        },

        computed: {
            score () {
                let correctAnswers = 0;
                this.questions.forEach(question => {
                    let correctAnswer = question.answers.find(answer => answer.id === question.answered).correct;
                    if (correctAnswer) correctAnswers++;
                });

                return correctAnswers;
            }
        },

        mounted () {
            let shuffledQuestions = shuffle(config.questions);
            this.questions = shuffledQuestions.slice(0, 10);
        },

        methods: {
            prev () {
                this.currentIndex -= 1;
            },
            next () {
                if (this.currentIndex === this.questions.length - 1) {
                    this.toggleScoreDialog(true);
                } else {
                    this.currentIndex += 1;
                }
            },
            hideWelcomeDialog () {
                this.showWelcomeDialog = false;
            },
            toggleScoreDialog (show) {
                this.showScoreDialog = show;
            },
            toggleDescriptionDialog (show, description) {
                this.showDescriptionDialog = show;
                this.description = description;
            },
        }
    }
</script>

<style lang="less">
    .quiz {
        max-width: 600px;
        margin: 0 auto;
        flex: 1 0 auto;
    }
</style>
