import { createApp } from 'vue'
import App from './App.vue'

import '../node_modules/normalize.css/normalize.css'
import '../src/assets/app.less'
import '../node_modules/codemirror/lib/codemirror.css'
import '../node_modules/codemirror/theme/monokai.css'
import '../node_modules/codemirror/mode/javascript/javascript.js'

createApp(App).mount('#app')
