<template>
    <div>
        <dark-mode-switcher />

        <quiz />
    </div>
</template>

<script>
    import Quiz from './components/Quiz.vue'
    import DarkModeSwitcher from './components/DarkModeSwitcher.vue'

    export default {
        name: 'app',
        components: {
            Quiz,
            DarkModeSwitcher,
        },

        data () {
            return {
                darkMode: false,
            }
        }
    }
</script>
